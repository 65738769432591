import { createStore } from 'vuex'

export default createStore({
  state: {


    // ip: 'localhost:3000',
    // ip:'43.154.234.125:3000',
    // ip:'43.154.234.125:9000',
    // ip:'43.154.234.125:2000',

    // ip: 'localhost:3000',
    // ip:'binjia.forgex.cn',
    // ip:'yangxuan.forgex.cn',
    ip:'forgex.cn:2000',

    //本地调试记得打开vue.config.js中的proxy

    // ip:'43.153.185.138:2000',

    // port:8080,
    port: 8888,
    // port:8081,


    mail: "miller@bin-jia.cn,jeffrey@bin-jia.cn",
    // mail:"skyfall@iskyfall.com",
    maskshow: false,
    updateSystem: false,
    ws: false,

    avoidKey: ['billmoney', 'billapplyer', 'status', 'newTime', 'papplyer', 'pchecktime', 'tprice',
      'ctnsgw', 'ctnsnw', 'ctnv', 'ctnsv', 'inctn', 'factorytp', 'factoryquantity', 'portctns', 'portv', 'lastedit', 'uploader', 'time'
    ],
    timeKey: ['clienttime', 'portordertime', 'portloadtime', 'factorytime', 'etd', 'eta'],

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
