<template>
  <transition name="mask">
    <div class="mask_for_all" v-show="$store.state.maskshow"></div>
  </transition>
  <SideBarComp></SideBarComp>
</template>

<script>
import SideBarComp from "../components/SideBarComp.vue"
export default {
  name: 'HomeView',
  components: {
    SideBarComp,
  },
}
</script>

<style>
  .mask_for_all{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: #434a502f;
    backdrop-filter: blur(2px);
  }
</style>
