<template>
    <transition name="ani">
        <div class="WarningCompBox" v-show="warningShow">
            <span class="iconfont icon-warnfill" id="WarningCompSpan"></span>
            <span>{{ messageText }}</span>
            <span class="iconfont icon-close" @click="closeBox" id="closeWarning"></span>
        </div>
    </transition>
    <transition  name="ani">
        <div class="WarningCompBoxA" v-show="successShow">
            <span class="iconfont icon-warnfill" id="WarningCompSpan"></span>
            <span>{{ messageText }}</span>
            <span class="iconfont icon-close" @click="closeBox" id="closeWarningB"></span>
        </div>
    </transition>
</template>

<script>
    export default{
        name:"WarningComp",
        props:['messageStamp','messageType','messageText'],
        watch:{
            messageStamp(){
                if(this.messageType===1){
                    this.warningShow = true
                    this.successShow = false
                    if(this.timer){
                        clearTimeout(this.timer)
                    }
                    this.timer = setTimeout(() => {
                        this.warningShow = false
                        this.successShow = false
                    }, 800);
                }else{
                    this.warningShow = false
                    this.successShow = true
                    if(this.timer){
                        clearTimeout(this.timer)
                    }
                    this.timer = setTimeout(() => {
                        this.warningShow = false
                        this.successShow = false
                    }, 800);
                }
            }
        },
        data(){
            return{
                warningShow:false,
                successShow:false,
                timer:"",
            }
        },
        methods:{
            closeBox(){
                this.warningShow = false
                this.successShow = false
            }
        }
    }
</script>

<style>
    .WarningCompBox{
        width: 500px;
        line-height: 20px;
        height: 32px;
        padding: 0 10px 0 10px ;
        position: absolute;
        z-index: 1000;
        top: 50px;
        left: calc(50% - 250px);
        background-color: var(--red-color);
        border-radius: 5px;
        box-shadow: var(--box-shadow);
        backdrop-filter: blur(5px) saturate(150%);
        color: white;
        text-align: center;
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
    }
    .WarningCompBox span,.WarningCompBoxA span{
        display: block;
        padding: 6px;
    }
    .WarningCompBoxA{
        width: 500px;
        line-height: 20px;
        height: 32px;
        padding: 0 10px 0 10px ;
        position: absolute;
        z-index: 1000;
        top: 50px;
        left: calc(50% - 250px);
        background-color: var(--greend-color);
        border-radius: 5px;
        box-shadow: var(--box-shadow);
        backdrop-filter: blur(5px) saturate(150%);
        color: white;
        text-align: center;
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
    }

    #closeWarning{
        cursor: pointer;
        color: white;
    }
    #closeWarningB{
        color: white;
    }
</style>