<template>
    <router-view />
</template>

<script>

</script>

<style>
:root {

    /* 新的颜色体系 */
    --theme-color: #007bff;
    --blue-color: #007bff;
    --blue2-color: rgb(201 217 255);
    --bluebg-color: #e0e8fd;
    --lightblue-color: #F0F0F2;

    --tabletr-color: #f3f3f3;
    --selected-color:#bfd9ff54;

    --green-color: #d8ffe9;
    --greend-color: #00c157;
    --greenb-color: #729b85;

    --yellowbg-color: #ffeec4;
    --yellow-color: #ffd500;

    --red-color: #f20505;
    --redbg-color: #ffd5d5;
    --redbg2-color: #fffbfb;

    --font-color: #23272b;
    --font2-color: #4e5154;
    --subfont-color: #606060;
    --thirdfont-color: #b2b5ba;

    --bg-color: #f8f8f8;
    --bg2-color: #ebecef;
    --tablebg-color: #eeeeee;

    /* --shadow-color: #ebebebb2; */
    --shadow-color: #ebeaef;
    --box-shadow: 0 0 10px 2px #ebebeb;
    --boxd-shadow: 0 0 10px 5px #94949469;

    --trhoverd-color: rgb(212, 212, 212);
    --trhover-color: rgba(235, 243, 255, 0.97);
    --trhoverb-color: rgba(209, 227, 255, 0.97);

    --light-gradient: linear-gradient(to right, #f0f4ff 0%, #d4dbf9 100%);
    --dark-gradient: linear-gradient(to right, rgb(227, 231, 253) 0%, #d4dbf9 100%);

    /* 变量 */
    --media-width: 1350px;

}

#app {
    /* font-family: Arial, Helvetica,sans-serif; */
    font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    overflow: hidden;
}

html,
body {
    width: 100%;
    height: 100%;
    font-size: 16pt;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid var(--blue-color) !important;
    border-radius: 2px;
    background-color: white;
    outline: none;
    cursor: pointer;
    position: absolute;
}

input[type="checkbox"]:checked {
    border: 1px solid var(--blue-color) !important;
    background-color: var(--blue-color);
}

input[type="checkbox"]:checked::before {
    content: "✔";
    position: absolute;
    top: -6px;
    left: 2px;
    font-size: 0.6rem;
    color: white;
}

::placeholder {
    color: var(--subfont-color);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.6rem;
    user-select: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    background-color: var(--thirdfont-color);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--font-color);
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: var(--tablebg-color);
    border-radius: 2px;
}

#routerView_outerbox {
    position: absolute;
    top: 0;
    left: 120px;
    padding: 0 10px 10px 0;
    display: flex;
    height: 100vh;
    background-color: var(--bg-color);
    box-sizing: border-box;
    width: calc(100vw - 120px);
    min-width: 800px;
    overflow: hidden;
    transition: 0.2s;
}



#routerView {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: var(--font-color);
}

.eac {
    color: var(--font-color) !important;
    font-weight: 400;
    background-color: var(--yellow-color) !important;
}

input:focus {
    box-shadow: 0 0 0 3px #bdeaff62;
}

.btn {
    width: 90px;
    height: 32px;
    background-color: var(--blue-color);
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    color: white;
    font-size: 0.6rem;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    font-weight: 600;
    /* box-shadow: var(--box-shadow); */
    -webkit-app-region: no-drag;
}

.btnborder {
    width: 90px;
    height: 32px;
    border: 1px solid var(--blue-color);
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    color: var(--blue-color);
    font-size: 0.6rem;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    font-weight: 600;
    /* box-shadow: var(--box-shadow); */
    -webkit-app-region: no-drag;
}

.btnshallow {
    width: 90px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    color: var(--blue-color);
    background-color: var(--bluebg-color);
    font-size: 0.6rem;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    font-weight: 600;
    /* box-shadow: var(--box-shadow); */
    -webkit-app-region: no-drag;
}

.btnwarning {
    width: 90px;
    height: 32px;
    /* background-color: var(--redbg-color); */
    border: 1px solid red;
    text-align: center;
    line-height: 30px;
    border-radius: 20px;
    color: var(--red-color);
    font-size: 0.6rem;
    font-weight: 600;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    /* box-shadow: var(--box-shadow); */
    -webkit-app-region: no-drag;
}

.btnyellow {
    width: 90px;
    height: 32px;
    background-color: var(--yellow-color);
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    color: var(--font-color);
    font-size: 0.6rem;
    font-weight: 600;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    /* box-shadow: var(--box-shadow); */
    -webkit-app-region: no-drag;
}


.btn:hover,
.btnborder:hover,
.btnshallow:hover,
.btnwarning:hover,
.btnyellow:hover {
    opacity: 0.5;
}


/* 窗口动画 */
.ani {
    /* Initial position and opacity */
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s;
}

.ani-move {
    transition: 0.3s;
}

.ani-enter-active,
.ani-leave-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateY(20px) scale(0.98);
    transition: all 0.3s;
}

.ani-leave-active {
    position: absolute;
}

.ani-enter-to {
    /* Final position and opacity */
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: all 0.3s;
}

.ani-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
    transform: translateY(-40px) scale(0.95);
    transition: all 0.3s;
}

/* 大幅度 */
.ani2 {
    /* Initial position and opacity */
    opacity: 0;
    transform: translateX(100px);
}

.ani2-move {
    transition: 0.3s;
}

.ani2-enter-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateX(0) scale(0.96);
    transform-origin: 0 0;
    transition: all 0.3s;
}

.ani2-leave-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateX(100px) scale(0.96);
    transform-origin: 0 0;
    transition: all 0.3s;
    position: absolute;
}

.ani2-enter-to {
    /* Final position and opacity */
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0) scale(1);
    transition: all 0.3s;
}

.ani2-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(-40px) scale(0.95);
    transform-origin: 0 0;
    transition: all 0.3s;
}

/* 表格动画 轻缓许多 */
.ani3 {
    /* Initial position and opacity */
    opacity: 0;
    transform: translateX(40px);
}

.ani3-move {
    transition: 0.3s;
}

.ani3-enter-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateX(0);
    transform-origin: 0 0;
    transition: all 0.3s;
}

.ani3-leave-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateX(40px);
    transform-origin: 0 0;
    transition: all 0.3s;
    position: absolute;
}

.ani3-enter-to {
    /* Final position and opacity */
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
    transition: all 0.3s;
}

.ani3-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(-40px);
    transition: all 0.3s;
}

/* 只是渐变 */
.ani4 {
    /* Initial position and opacity */
    opacity: 0;
}

.ani4-move {
    transition: 0.3s;
}

.ani4-enter-active {
    /* Animation duration and easing function */
    opacity: 0;
}

.ani4-leave-active {
    /* Animation duration and easing function */
    opacity: 0;
}

.ani4-enter-to {
    /* Final position and opacity */
    opacity: 1;
}

.ani4-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
}


/* 窗口动画 */
.ani5 {
    /* Initial position and opacity */
    opacity: 0;
    transform: translateY(20px);
}

.ani5-move {
    transition: 0.2s;
}

.ani5-enter-active,
.ani5-leave-active {
    /* Animation duration and easing function */
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s;
}

.ani5-leave-active {
    position: absolute;
}

.ani5-enter-to {
    /* Final position and opacity */
    opacity: 1;
    transform: translateY(0);
}

.ani5-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
}



/* mask */
.mask {
    /* Initial position and opacity */
    opacity: 1;
}

.mask-move {
    transition: 0.1s;
}

.mask-enter-active {
    /* Animation duration and easing function */
    opacity: 0;
    transition: all 0.1s ease;
}

.mask-leave-active {
    /* Animation duration and easing function */
    opacity: 1;
    transition: all 0.1s ease;
    position: absolute;
}

.mask-enter-to {
    /* Final position and opacity */
    opacity: 1;
    transition: all 0.1s ease;
}

.mask-leave-to {
    /* Final position and opacity when leaving */
    opacity: 0;
    transition: all 0.1s ease;
}



.required::after {
    content: ' *';
    position: absolute;
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 2px;
}

.box_header {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-color);
    line-height: 42px;
    padding: 0 10px 0 10px;
    color: var(--subfont-color);
    font-size: 0.6rem;
    font-weight: 600;
    border-bottom: 1px solid var(--tablebg-color);
    user-select: none;
    cursor: move;
}

.box_ops {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    /* background-color: var(--bg-color);
    border-top: 1px solid var(--tablebg-color); */
}

.box_ops span {
    display: block;
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 32px;
    width: 120px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
}

.box_ops span:nth-child(1) {
    color: var(--red-color);
    /* background-color: var(--redbg-color); */
}

.box_ops span:nth-child(2) {
    color: white;
    background-color: var(--blue-color);
}

.box_ops span:hover {
    opacity: 0.5;
}

input:read-only,
textarea:read-only {
    border: 1px solid var(--thirdfont-color) !important;
}


#copy_box,
#delete_box {
    width: 300px;
    height: 300px;
    background-color: #fff;
    box-shadow: var(--boxd-shadow);
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    border-radius: 10px;
    z-index: 10000;
    overflow: hidden;
}

#copy_box_body,
#delete_box_body {
    margin: 20px 10px 10px 10px;
}

#copy_box_body span,
#delete_box_body span {
    display: block;
    text-align: center;
}

#copy_box_body span:nth-child(1),
#delete_box_body span:nth-child(1) {
    font-size: 0.8rem;
    color: var(--subfont-color);
    padding: 30px 0 30px 0;
}

#copy_box_body span:nth-child(2),
#delete_box_body span:nth-child(2) {
    font-size: 0.8rem;
    color: var(--thirdfont-color);
}

.table_body_input {
    width: 100%;
    height: 40px;
    border: 2px solid var(--blue-color);
    border-radius: 2px;
    outline: none;
    padding-left: 2px;
}

/* 单元格宽度 */
.uprice,
.tprice,
.ctnl,
.ctnw,
.ctnh,
.ctnv,
.ctnsv,
.inctn,
.upricecny,
.factoryup,
.factoryquantity,
.factorytp,
.factorydeposit,
.factorytax,
.factorymoney,
.portshortname,
.portquantity,
.portctns,
.portv {
    width: 130px;
}

.clienttime,
.altorder,
.uploader,
.ETD,
.contractnumber,
.client,
.clientdepartment,
.quantity,
.orderstatus,
.factorytime,
.ctns,
.ctngw,
.ctnnw,
.ctnsgw,
.ctnsnw,
.factorymoneyprogress,
.portbillnum,
.portname,
.portresname,
.partordertime,
.portloadtime,
.eta,
.firstsample,
.layout,
.masssample,
.test,
.inspection,
.portordertime,
.etd {
    width: 140px;
}


.article{
    width: 160px;
}

.lastedit,
.altorder,
.style,
.ordernumber,
.pack,
.pname,
.time,
.factory,
.factorymaterial,
.factorymaterialprice {
    width: 200px;
}

.pnamee,
.inctn_detail {
    width: 300px;
}

.id,
.edithistory,
.orderid,
.moneyhistory,
.notehistory,
.img {
    display: none !important;
}

.lastedit {
    text-align: right !important;
}

/* 不可编辑 */
tbody .tprice,
tbody .ctnsgw,
tbody .ctnsnw,
tbody .ctnv,
tbody .ctnsv,
tbody .inctn,
tbody .factorytp,
tbody .factoryquantity,
tbody .portctns,
tbody .portv,
tbody .lastedit,
tbody .uploader,
tbody .time,
tbody .newTime,
tbody .papplyer,
tbody .pchecktime
{
    color: var(--thirdfont-color) !important;
}

.checkbox,
.n,
.ordernumber,
.bill,
.pcontractnumber,
.billnum,
.user,
.absent_name,
.applyer {
    position: sticky !important;
    background-color: inherit;
    z-index: 1;
    font-weight: 600;
    color: var(--font-color) !important;
    /* backdrop-filter: blur(2px); */
    /* border: 1px solid red; */
}

.checkbox {
    left: 0;
}

.n {
    left: 40px;
    user-select: none;
    /* padding: 0 2px 0 10px !important; */
    text-align: center !important;
}

.ordernumber,
.bill,
.pcontractnumber,
.billnum,
.applyer,
.user,
.absent_name {
    left: 120px !important;
}

/* 收汇 */
.id,
.img {
    display: none !important;
}

.bill,
.client,
.exportTime,
.moneyinTime,
.sendBillTime,
.totalPrice,
.finalPrice {
    width: 120px;
}

.newTime {
    width: 160px;
}

.status {
    width: 60px;
}

.decNo,
.note,
.applynote {
    width: 400px;
}

.id {
    display: none !important;
}

.applyclass,
.status {
    width: 120px;
}

.destination,
.applydate,
.applyer,
.applymoney,
.billbigy,
.applyfactory,
.applyto,
.passtime {
    width: 200px;
}

.applyorder,
.moneyapplycontract {
    width: 400px;
}</style>
