import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../mainviews/HomeView'
import LoginView from '../subviews/LoginView'
// import LoginView from '../mainviews/HomeView'
import CryptoJS from 'crypto-js';
import store from '../store/index';
import axios from 'axios';

const routes = [
  {
    path: '/',
    name: 'index',
    component: LoginView,
  }, {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/MyCenter',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/MyCenter',
        name: 'MyCenter',
        component: () => import('../subviews/MyCenter.vue'),
        meta: { admin: false }
      }, {
        path: '/CalenderView',
        name: 'CalenderView',
        component: () => import('../subviews/CalenderView.vue'),
        meta: { admin: false }
      }, {
        path: '/ChatGpt',
        name: 'ChatGpt',
        component: () => import('../subviews/ChatGpt.vue'),
        meta: { admin: false }
      }, {
        path: '/AbsentApply',
        name: 'AbsentApply',
        component: () => import('../subviews/AbsentApply.vue'),
        meta: { admin: false }
      }, {
        path: '/OverTime',
        name: 'OverTime',
        component: () => import('../subviews/OverTime.vue'),
        meta: { admin: false }
      }, {
        path: '/BusinessTrip',
        name: 'BusinessTrip',
        component: () => import('../subviews/BusinessTrip.vue'),
        meta: { admin: false }
      },{
        path: '/SysInfo',
        name: 'SysInfo',
        component: () => import('../subviews/SysInfo.vue'),
      }, {
        path: '/ForeignOrder',
        name: 'ForeignOrder',
        component: () => import('../subviews/ForeignOrder.vue'),
      }, {
        path: '/DomesticOrder',
        name: 'DomesticOrder',
        component: () => import('../subviews/DomesticOrder.vue'),
      }, {
        path: '/OrderBin',
        name: 'OrderBin',
        component: () => import('../subviews/OrderBin.vue'),
      }, {
        path: '/PaymentApprove',
        name: 'PaymentApprove',
        component: () => import('../subviews/PaymentApprove.vue'),
        // meta:{admin:true}
      }, {
        path: '/ExportIncome',
        name: 'ExportIncome',
        component: () => import('../subviews/ExportIncome.vue'),
        meta: { admin: true }
      }, {
        path: '/ShipmentInfo',
        name: 'ShipmentInfo',
        component: () => import('../subviews/ShipmentInfo.vue'),
      }, {
        path: '/ClearenceInfo',
        name: 'ClearenceInfo',
        component: () => import('../subviews/ClearenceInfo.vue'),
      }, {
        path: '/SupplierInfo',
        name: 'SupplierInfo',
        component: () => import('../subviews/SupplierInfo.vue'),
      }, {
        path: '/ClientInfo',
        name: 'ClientInfo',
        component: () => import('../subviews/ClientInfo.vue'),
      }, {
        path: '/DataInfo',
        name: 'DataInfo',
        component: () => import('../subviews/DataInfo.vue'),
        meta: { admin: true }
      }, {
        path: '/CompanyData',
        name: 'CompanyData',
        component: () => import('../subviews/CompanyData.vue'),
      }, {
        path: '/SupplierData',
        name: 'SupplierData',
        component: () => import('../subviews/SupplierData.vue'),
      }, {
        path: '/ClientData',
        name: 'ClientData',
        component: () => import('../subviews/ClientData.vue'),
      }, {
        path: '/UserManage',
        name: 'UserManage',
        component: () => import('../subviews/UserManage.vue'),
        meta: { admin: true }
      }, {
        path: '/SysConfig',
        name: 'SysConfig',
        component: () => import('../subviews/SysConfig.vue'),
        meta: { admin: true }
      },
    ]
  }, {
    path: '/404',
    name: 'notfound',
    component: () => import('../subviews/NotFound.vue'),
  }, {
    path: '/:catchAll(.*)',
    redirect: "/404",
  }
]

const router = createRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  history: createWebHashHistory(),
  routes
})

//localstorage加密：
let secret_key = "1rfsvx41a46BW1Bvxh"
function decrypt(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secret_key);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}


//查询relogin的状态，如果为true，则跳转到登录页面
function relogin() {
  return new Promise((resolve) => {
    axios.get(`https://${store.state.ip}/relogin`, {
      params: {
        user: decrypt(localStorage.getItem('user'))
      }
    }).then(res => {
      resolve(res.data)
    })
  })
}

function goLogin() {
  router.push('/login')
}

router.beforeEach((to, from, next) => {
  //关掉mask
  store.maskshow = false
  let isLoged = localStorage.getItem('email')
  if (isLoged) {
    //如果是管理员，且访问的是管理员页面，则放行，否则跳转到404页面
    let isAdmin = JSON.parse(decrypt(localStorage.getItem('permissions')))[0].value
    //这里添加各种权限：
    if (to.meta.admin === true && isAdmin === true) {
      next()
    } else if (to.meta.admin === true && isAdmin !== true) {
      next({
        path: ('/404')
      })
    } else {
      next()
    }
    //每次跳转页面都验证
    relogin().then((res) => {
      //如果尚未登录，且访问的不是登录页面，则跳转到登录页面
      if (res[0].relogin !== 'hasloged' && to.path !== "/login") {
        goLogin()
      }
    })
  } else {
    //未登录，引导去登录页面：
    if (to.path === "/login") {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
