<template>
    <div id="login_bg">
        <div id="loginBgMask"></div>
        <div id="login_header">
            <div id="login_header_left">
                <img src="../../public/forgex.png" alt="" id="forgexlogo">
                <span>福纪未来</span>
            </div>
            <div id="nav_mid"></div>
            <div id="login_header_right">
                <span>访问官网</span>
                <span>商务合作</span>
            </div>
        </div>

        <div id="loginBox">
            <span id="loginTitle">使用账户密码登录</span>
            <div class="loginInputBox">
                <input type="text" v-model="email" placeholder="邮箱">
            </div>
            <div class="loginInputBox">
                <input type="password" v-model="upwd" placeholder="密码">
            </div>
            <div id="forgetPwd">
                <span>忘记密码？</span>
                <span v-show="loginErr" id="logErrBox">密码或用户名错误！</span>
                <span v-show="loginVerifyValue" id="logErrBox">请输入正确的格式！</span>
                <span v-show="loginSuc" id="logSucBox">登录成功</span>
            </div>
            <button id="loginBtn" @click.prevent="loginVerify">登录</button>
        </div>
    </div>
    <WarningComp :messageStamp="messageStamp" :messageType="messageType" :messageText="messageText"></WarningComp>
</template>

<script>
import CryptoJS from 'crypto-js';
import router from '@/router'
import axios from 'axios';
import WarningComp from '@/components/WarningComp.vue'
export default {
    components: {
        WarningComp
    },
    data() {
        return {
            email: '',
            upwd: '',
            loginErr: false,
            loginSuc: false,
            loginVerifyValue: false,

            messageStamp: "1",
            messageType: 1,
            messageText: "",

            //localstorage加密
            secret_key: "1rfsvx41a46BW1Bvxh"
        }
    },
    methods: {

        setNewWarning(messageType, messageText) {
            this.messageType = messageType
            this.messageText = messageText
            this.messageStamp = Date.now()
        },

        //格式验证：
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        loginVerify() {
            let a = this.validateEmail(this.email)
            let b = this.email.length > 5
            let c = this.upwd.length > 5
            if (a && b && c) {
                this.loginCheck()
            } else {
                this.loginSuc = false
                this.loginErr = false
                this.loginVerifyValue = true
            }
        },

        loginCheck() {
            axios.post(`https://${this.$store.state.ip}/login`, {
                email: this.email,
                upwd: this.upwd,
            }).then(res => {
                if (res.data.length > 0) {
                    this.loginSuc = true
                    this.loginErr = false
                    this.loginVerifyValue = false
                    setTimeout(() => {
                        router.push('/MyCenter')
                    }, 2000);
                    this.setAuth(res.data[0])
                    this.verifyBrowser()
                }
                else {
                    this.loginErr = true
                    this.loginSuc = false
                    this.loginVerifyValue = false
                }
            })
        },
        //localstorage加密：
        encrypt(data) {
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.secret_key);
            return encryptedData.toString();
        },
        decrypt(encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, this.secret_key);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        },
        //把权限数据记录在本地
        setAuth(userinfo) {
            //设置概览字段：
            localStorage.setItem("allinfoshow", userinfo.allinfoshow)
            //设置email字段：
            localStorage.setItem("email", this.encrypt(userinfo.email))
            //设置客户与部门：
            localStorage.setItem("dep", this.encrypt(userinfo.dep))
            //设置权限：
            localStorage.setItem("permissions", this.encrypt(userinfo.permission))
            //设置英文名、中文名、头像地址
            localStorage.setItem("user", this.encrypt(userinfo.user))
            localStorage.setItem("usercn", this.encrypt(userinfo.usercn))
            localStorage.setItem("headphoto", userinfo.headphoto)
            localStorage.setItem("job", userinfo.job)
            this.setFactory()
            this.setClient()
        },
        //客户也需要另外请求
        setClient() {
            let clients = []
            axios.get(`https://${this.$store.state.ip}/getClient`).then(res => {
                clients = res.data.map((item) => {
                    return item.client
                })
                localStorage.setItem("client", clients)
            })
        },
        setFactory() {
            localStorage.setItem("minimizeInfoShowStyle", JSON.stringify({
                width: "0px",
                paddingLeft: "0px"
            }))
            //工厂需要另外请求
            let factorys = []
            let factorys_detail = []
            axios.get(`https://${this.$store.state.ip}/getFactory`).then(res => {
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        factorys.push(res.data[i].fullname)
                        factorys_detail.push(res.data[i])
                    }
                    localStorage.setItem("factory", factorys)
                    localStorage.setItem("factory_detail", this.encrypt(factorys_detail))
                }
            })
        },
        //判断用户浏览器：
        verifyBrowser() {
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('msie') > -1 || ua.indexOf('trident') > -1) {
                this.setNewWarning(1, "请使用chrome浏览器！")
            } else if (ua.indexOf('edg') > -1) {
                this.setNewWarning(1, "请使用chrome浏览器！")
            } else if (ua.indexOf('firefox') > -1) {
                this.setNewWarning(1, "请使用chrome浏览器！")
            } else if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1) {
                this.setNewWarning(1, "请使用chrome浏览器！")
            } else if (ua.indexOf('chrome') > -1 && ua.indexOf('safari') > -1) {
                localStorage.setItem('browser', "chrome")
            } else {
                this.setNewWarning(1, "请使用chrome浏览器！")
            }
        }
    }
}

</script>

<style scoped>
#nav_mid {
    flex: 1;
    height: 60px;
    -webkit-app-region: drag;
}

#login_bg {
    width: 100%;
    min-width: 1200px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #a7c4b7;
    background-image: url('../../public/house.png');
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: 20% 45%;
    animation-name: house;
    animation-duration: 4s;
    animation-iteration-count: infinite;

}

@keyframes house {
    0% {
        background-position: 20% 45%;
    }

    60% {
        background-position: 20% 55%;
    }

    100% {
        background-position: 20% 45%;
    }

    0% {
        background-position: 20% 45%;
    }
}

@media screen and (max-width: 600px) {
    #loginBgMask {
        width: 100%;
        backdrop-filter: saturate(90%) blur(1px) brightness(0.7);
        -webkit-backdrop-filter: saturate(90%) blur(1px) brightness(0.7);
        background-color: rgba(74, 74, 74, 0.018);
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    #loginBox {
        left: calc(50vw + 200px) !important;
    }
}

#login_header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 20px;
    backdrop-filter: saturate(150%) blur(8px);
    -webkit-backdrop-filter: saturate(150%) blur(8px);
    background-color: rgba(255, 255, 255, 0.616);
}

#login_header_left img {
    height: 80px;
    padding: 30px;
}

#login_header_left {
    display: flex;
    line-height: 80px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0 20px 0 20px;
    color: var(--subfont-color);
    letter-spacing: 3px;
}

#login_header_right {
    display: flex;
    height: 30px;
    margin-top: 25px;
}

#login_header_right span {
    line-height: 30px;
    font-size: small;
    font-weight: bold;
    padding: 0 30px 0 30px;
    color: var(--font-color);
    letter-spacing: 2px;
    border: 1px solid var(--font-color);
    border-radius: 20px;
    cursor: pointer;
    margin: 0 10px 0 10px;
}

#login_header_right span:hover {
    opacity: .5;
}

#loginBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 400px;
    height: 400px;
    padding: 20px 20px 20px 20px;
    background-color: #ffffff5b;
    border-radius: 20px;
    position: absolute;
    left: calc(100vw - 800px);
    top: calc(50vh - 200px);
}

#loginTitle {
    display: block;
    color: var(--font-color);
    font-weight: 600;
    text-align: center;
    line-height: 40px;
}

.loginInputBox {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.loginInputBox input {
    width: 80%;
    height: 40px;
    outline: none;
    border: 1px solid var(--thirdfont-color);
    border-radius: 50px;
    padding-left: 15px;
}

#loginBtn {
    width: 80%;
    height: 40px;
    background-color: var(--blue-color);
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
}

#loginBtn:hover {
    opacity: 0.6;
}

#logErrBox,
#logSucBox {
    font-size: small;
    display: flex;
    justify-content: space-between;
}

#logErrBox {
    color: red !important;
}

#logSucBox {
    color: var(--blue-color);
}

#forgetPwd {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

#forgetPwd span {
    color: var(--blue-color);
    font-size: 0.6rem;
    cursor: pointer;
    line-height: 20px;
    padding-left: 5px;
}</style>